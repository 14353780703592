/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import ExternalLink from "../components/ExternalLink";
import PageContainer from "../components/PageContainer";
import SectionArticle from "../components/SectionArticle";

import { Helmet } from "react-helmet";

export const TermsAndConditionsPageTemplate = ({ title, terms }) => {
  return (
    <PageContainer>
      <SectionTitle title={title}>{title}</SectionTitle>
      {terms?.map((term, index) => (
        <SectionArticle
          key={index}
          text={term.body}
          phone={term.phone}
          email={term.email}
          address={term.adress}
          title={term.heading}
        />
      ))}
    </PageContainer>
  );
};

TermsAndConditionsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  terms: PropTypes.array.isRequired,
};

const TermsAndConditionsPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <TermsAndConditionsPageTemplate
        title={post.frontmatter.title}
        terms={post.frontmatter.terms}
      />
      <Helmet titleTemplate="%s | GDPR">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>
    </Layout>
  );
};

TermsAndConditionsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TermsAndConditionsPage;

export const conditionsPageQuery = graphql`
  query TermsAndConditionsPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "terms_and_conditions-page" } }
    ) {
      id
      frontmatter {
        title
        terms {
          heading
          body
          phone
          email
          address
        }
      }
    }
  }
`;
