/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import remark from "remark";
import remarkHTML from "remark-html";
import ExternalLink from "./ExternalLink";

const SectionArticle = ({ text, phone, email, address, title }) => {
  const value = remark().use(remarkHTML).processSync(text).toString();
  const addresstext = remark().use(remarkHTML).processSync(address).toString();
  return (
    <div>
      <section
        sx={{
          textAlign: "center",
          maxWidth: "1200px",
          mx: "auto",
          mt: 5,
          mb: 6,
        }}
      >
        <h2
          sx={{
            textAlign: "center",
            fontSize: [3, 5],
            textTransform: "uppercase",
            mb: 4,
          }}
        >
          {title}
        </h2>

        <div
          sx={{
            p: { my: 3 },
            h3: { fontSize: [3, 4], mt: [4, 5] },
            h2: { fontSize: [3, 4], mt: [4, 5] },
            h1: { fontSize: [3, 4], mt: [4, 5] },
            ul: {
              margin: "auto",
              display: "inline-block",
              li: {
                listStyleType: "circle",
                my: [3, null, 2],
                textAlign: "left",
                ml: ["15%", "20%"],
              },
            },
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
        <div
          sx={{ display: ["block", "none"], p: { my: 3 } }}
          dangerouslySetInnerHTML={{ __html: addresstext }}
        />

        {phone && (
          <div sx={{ display: "block" }}>
            <ExternalLink color={"#333"} url={`tel:${phone}`}>
              Tel: {phone}
            </ExternalLink>
          </div>
        )}

        {email && (
          <div sx={{ display: "block" }}>
            <ExternalLink url={`mailto:${email}`} color={"#333"}>
              Email: {email}
            </ExternalLink>
          </div>
        )}
      </section>
    </div>
  );
};

export default SectionArticle;

SectionArticle.propTypes = {
  section: PropTypes.object,
};
