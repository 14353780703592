/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const SectionTitle = ({ title }) => {
  return (
    <h2
      sx={{
        textAlign: "center",
        fontSize: [5, 6],
        textTransform: "uppercase",
        mb: 4,
      }}
    >
      {title}
    </h2>
  );
};

export default SectionTitle;

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
