/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const PageContainer = ({ children }) => (
  <div sx={{ pt: "70px", mx: [4, 5], }}>
    <div sx={{ mt: 6, my: 7 }}>{children}</div>
  </div>
);

export default PageContainer;

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
